// src/App.js
import React from 'react';
import CheckboxWithText from './CheckboxWithText';
import { Container, CssBaseline, Typography } from '@mui/material';

const App = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h4" gutterBottom>
          Parking Manager
        </Typography>
        <CheckboxWithText />
      </div>
    </Container>
  );
};

export default App;