import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormControlLabel, Checkbox, Typography, Box, TextField } from '@mui/material';

// Base URL for the backend service
const _base_url = process.env.REACT_APP_BACK_END_URL || "http://127.0.0.1:5000";

const CheckboxWithText = () => {
  const [users, setUsers] = useState([]); // State to hold the dynamic users list
  const [userStates, setUserStates] = useState({});
  const [parkingStatus, setParkingStatus] = useState({ available_parking: 'Loading...', reserved_parking_spots: 'Loading...' });

  // Fetch users and parking status when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the list of users (which is a dictionary)
        const usersResponse = await axios.get(`${_base_url}/users/`);
        const fetchedUsers = Object.keys(usersResponse.data).map(key => ({
          name: key,
          ...usersResponse.data[key]
        }));
        setUsers(fetchedUsers);
        
        // Fetch data for each user
        const userStates = {};
        await Promise.all(fetchedUsers.map(async (user) => {
          try {
            const response = await axios.get(`${_base_url}/users/${user.name}`);
            userStates[user.name] = {
              reserve_parking: response.data.reserve_parking,
              is_present: response.data.is_present,
              enter_time: response.data.enter_time || "N/A", // Add enter_time
              exit_time: response.data.exit_time || "N/A"    // Add exit_time
            };
          } catch (error) {
            console.error(`Error fetching status for ${user.name}:`, error);
          }
        }));
        setUserStates(userStates);
  
        // Fetch parking status
        const parkingResponse = await axios.get(`${_base_url}/parking_status/`);
        setParkingStatus({
          available_parking: parkingResponse.data.available_parking,
          reserved_parking_spots: parkingResponse.data.reserved_parking_spots
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  // Update the checkbox state on change and send PATCH request
  const handleChange = async (userName, event) => {
    const newChecked = event.target.checked;
    setUserStates(prevState => ({
      ...prevState,
      [userName]: {
        ...prevState[userName],
        reserve_parking: newChecked
      }
    }));

    try {
      await axios.patch(`${_base_url}/users/${userName}`, { reserve_parking: newChecked });
      console.log(`Patch user ${userName}:`);
    } catch (error) {
      console.error(`Error updating status for ${userName}:`, error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1">Available parking spots:</Typography>
          <TextField
            variant="outlined"
            size="small"
            value={parkingStatus.available_parking}
            InputProps={{
              readOnly: true,
            }}
            sx={{ textAlign: 'right' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1">Reserved parking spots:</Typography>
          <TextField
            variant="outlined"
            size="small"
            value={parkingStatus.reserved_parking_spots}
            InputProps={{
              readOnly: true,
            }}
            sx={{ textAlign: 'right' }}
          />
        </Box>
      </Box>

      {users.map(user => (
        <Box
          key={user.name}  // Use user.name as the unique key
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userStates[user.name]?.reserve_parking || false}  // Use userStates[user.name]
                  onChange={(event) => handleChange(user.name, event)}  // Pass user.name to handleChange
                />
              }
              label={<Typography>{user.name}</Typography>}  // Display user.name
            />
          </Box>
          <TextField
            variant="outlined"
            size="small"
            label="Details"
            multiline
            rows={3}  // Set rows to accommodate 3 lines
            value={
              `${userStates[user.name]?.is_present ? "Present" : "Not present"}\n` + 
              `Entry Time: ${userStates[user.name]?.enter_time}\n` + 
              `Exit Time: ${userStates[user.name]?.exit_time}`
            }
            InputProps={{
              readOnly: true,
            }}
            sx={{ textAlign: 'right' }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CheckboxWithText;